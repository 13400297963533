import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Action, Getter, namespace } from "vuex-class";
import { APPOINTMENT_NAMESPACE } from "@/_store/appointment";
import { IAppointment, IConfigAppointmentModule } from "@/_store/types";
import { deepClone } from "fast-json-patch";
import ContactAppointmentMail from "./MailTemplate/ContactAppointmentMail.vue";
import moment from "moment";
import { sendMessage, getBoolean, getAddressProject } from "@/_utils";
import { IProject } from "@/model/project.model";
import { LANGUAGE_CONST } from "@/_common";

const appointmentModule = namespace(APPOINTMENT_NAMESPACE);

@Component({
  components: {
    ContactAppointmentMail,
  },
})
export default class ContactAppointmentProject extends Vue {
  @appointmentModule.Getter getConfig!: IConfigAppointmentModule;

  @Action("getProject", { namespace: APPOINTMENT_NAMESPACE })
  private getProjectAction!: (criteria: {
    objectId: string;
    key: string;
  }) => Promise<any>;

  @Action("sendContactAppointment", { namespace: APPOINTMENT_NAMESPACE })
  private sendContactAppointment!: (criteria: {
    objectId: string;
    key: string;
    data: any;
    email: string;
    noEmail: boolean;
  }) => Promise<void>;

  @Getter("getAppointments", { namespace: APPOINTMENT_NAMESPACE })
  private getAppointments!: IAppointment[];

  @Getter("getAppointmentByID", { namespace: APPOINTMENT_NAMESPACE })
  private getAppointmentByID!: (id: string) => IAppointment;

  @Action("checkAppointmentExists", { namespace: APPOINTMENT_NAMESPACE })
  private checkAppointmentExists!: (id: number) => Promise<boolean>;

  @Getter("getAppointmentClicked", { namespace: APPOINTMENT_NAMESPACE })
  private getAppointmentClicked!: number;

  @Getter("getProject", { namespace: APPOINTMENT_NAMESPACE })
  private getProject!: IProject;

  @Getter("getStatusSendFormLoading", { namespace: APPOINTMENT_NAMESPACE })
  private getStatusSendFormLoading!: boolean;

  @Getter("getStatusSendForm", { namespace: APPOINTMENT_NAMESPACE })
  private getStatusSendForm!: string;

  get buttonBackColor() {
    return "#" + this.getConfig.buttonBackColor || "";
  }

  get buttonFontColor() {
    return "#" + this.getConfig.buttonFontColor || "";
  }

  get font() {
    return this.getConfig.font || "";
  }

  get projectPrice() {
    let price = "";
    if (
      this.getProject &&
      (this.getProject.PriceMin || this.getProject.PriceMax)
    ) {
      price =
        this.getProject.PriceMin === this.getProject.PriceMax
          ? `${this.getProject.PriceMin || this.getProject.PriceMax} ‎€`
          : `${this.$t("FROM")} <b>${this.getProject.PriceMin} €</b> ${this.$t(
              "TO"
            )} <b>${this.getProject.PriceMax} €</b>`;
      return price;
    }
    return "";
  }

  get projectPicture() {
    if (this.getProject) {
      if (this.getProject.LargePicture) {
        return this.getProject.LargePicture;
      }
      if (this.getProject.LargePictureItem) {
        return this.getProject.LargePictureItem.Url;
      }
      if (this.getProject.MediumPictureItem) {
        return this.getProject.MediumPictureItem.Url;
      }
      if (this.getProject.SmallPictureItem) {
        return this.getProject.SmallPictureItem.Url;
      }
    }
    return "";
  }

  get projectAddress() {
    if (this.getProject) {
      return getAddressProject(this.getProject);
    }
    return "";
  }

  get project() {
    if (this.getProject) {
      return this.getProject;
    }
    return {};
  }

  private secretKey: string = "";
  private objectId: string = "";
  private isMobile: string = "";
  private appointmentId: string = "";
  private subject_mail = "";
  private email = "";
  private logo = "";
  private attach_mail = false;
  private showTermOfServices = true;

  private form = {
    AddressTitle: "",
    FirstName: "",
    Name: "",
    PhoneNumber: "",
    Email: "",
    // Content: '',
    IsReceivedEmail: false,
    roofs: [],
  };

  private roofsCheck = {
    ReadDescription: true,
    custom1: false,
    custom2: false,
  };

  private formDisable = true;

  private AddressTitleOptions: { text: string; value: string | null }[] = [];

  get formLoading() {
    return this.getStatusSendFormLoading;
  }

  get formStatus() {
    return this.getStatusSendForm;
  }

  get formRoofs() {
    return this.form.roofs;
  }

  set formRoofs(val) {
    this.form.roofs = val;
    this.checkRoofs();
  }

  get appointment() {
    return this.getAppointmentByID(this.appointmentId) || null;
  }

  get appointmentTime() {
    const appointmentByID = this.getAppointmentByID(this.appointmentId);
    if (typeof appointmentByID !== "undefined" && appointmentByID) {
      const appointment = deepClone(appointmentByID);
      const startTime = new Date(appointment.StartTime);
      const endTime = new Date(appointment.EndTime);
      appointment.ddStart =
        (startTime.getDate() < 10 ? "0" : "") + startTime.getDate();
      appointment.mmStart =
        (startTime.getMonth() + 1 < 10 ? "0" : "") + (startTime.getMonth() + 1);
      appointment.yyyyStart = startTime.getFullYear();
      appointment.hoursStart =
        (startTime.getHours() < 10 ? "0" : "") + startTime.getHours();
      appointment.minStart =
        (startTime.getMinutes() < 10 ? "0" : "") + startTime.getMinutes();
      appointment.hoursEnd =
        (endTime.getHours() < 10 ? "0" : "") + endTime.getHours();
      appointment.minEnd =
        (endTime.getMinutes() < 10 ? "0" : "") + endTime.getMinutes();
      const time = `${appointment.ddStart}/${appointment.mmStart}/${
        appointment.yyyyStart
      } 
      ${this.$t("FROM")} ${appointment.hoursStart}:${appointment.minStart} 
      ${this.$t("TO")} ${appointment.hoursEnd}:${appointment.minEnd}`;
      return time;
    }
    return "";
  }

  get confirmReadDescription() {
    if (
      this.getConfig.confirmReadDescription &&
      this.getConfig.confirmReadDescription !== "undefined"
    ) {
      return this.getConfig.confirmReadDescription;
    }
    if (this.getProject) {
      const str = this.$t("APPOINTMENT_CONFIRM_READ_DESCRIPTION") as string;
      return str;
    }
    return "";
  }

  get showConfirmReadDescription() {
    if (
      this.getConfig.showConfirmReadDescription &&
      this.getConfig.showConfirmReadDescription === "no"
    ) {
      this.roofsCheck.ReadDescription = false;
      return false;
    }
    if (this.getProject) {
      this.roofsCheck.ReadDescription = true;
      return true;
    }
    this.roofsCheck.ReadDescription = false;
    return false;
  }

  get conditionCustom1() {
    if (
      this.getConfig.conditionCustom1 &&
      this.getConfig.conditionCustom1 !== ""
    ) {
      this.roofsCheck.custom1 = true;
      return this.getConfig.conditionCustom1 as string;
    } else {
      this.roofsCheck.custom1 = false;
      return "";
    }
  }

  get conditionCustom2() {
    if (
      this.getConfig.conditionCustom2 &&
      this.getConfig.conditionCustom2 !== ""
    ) {
      this.roofsCheck.custom2 = true;
      return this.getConfig.conditionCustom2 as string;
    } else {
      this.roofsCheck.custom2 = false;
      return "";
    }
  }

  get isMobileView() {
    if (this.isMobile && this.isMobile === "true") {
      return true;
    }
    return false;
  }

  get getConfirmTimeAppointment() {
    if (this.appointment) {
      let startDate = moment(this.appointment.StartTime).locale(
        this.$i18n.locale
      );
      let endDate = moment(this.appointment.EndTime).locale(this.$i18n.locale);
      return `${startDate.format("HH:mm")}, ${startDate.format(
        "dddd D MMMM YYYY"
      )}`;
    }
  }

  get getDurationTimeAppointment() {
    if (this.appointment) {
      let startDate = moment(this.appointment.StartTime).locale(
        this.$i18n.locale
      );
      let endDate = moment(this.appointment.EndTime).locale(this.$i18n.locale);
      let duration = moment.duration(endDate.diff(startDate)).asMinutes();
      return duration + " min";
    }
  }

  get emailSend() {
    let email = this.getConfig.email;
    if (this.getConfig.emailTest && this.getConfig.emailTest !== "") {
      email = this.getConfig.emailTest;
    }
    if (this.appointment.UserEmail && this.appointment.UserEmail !== "") {
      email = this.appointment.UserEmail;
    }
    return email;
  }

  get observer() {
    if (this.$refs.observer) {
    }
    return "";
  }

  public async mounted() {
    this.subject_mail = this.$t("CONTACT_APPOINTMENT") as string;
    this.AddressTitleOptions = [
      { text: this.$t("MR") as string, value: "MR" },
      { text: this.$t("MS") as string, value: "MS" },
    ];
    this.form.AddressTitle = "MR" as string;
    this.secretKey = this.getConfig.key as string;
    this.objectId = this.getConfig.objectId as string;
    this.email = this.getConfig.email as string;
    this.logo = this.getConfig.logo as string;
    this.isMobile = this.getConfig.isMobile as string;
    this.appointmentId = this.getAppointmentClicked.toString();
    this.handleSendHeight();
    sendMessage({ type: "focus-modal", mess: "" });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  handleSendHeight() {
    if (this.getConfig.isMobile === "false") {
      const appEl = document.getElementById("app");
      if (appEl) sendMessage({ type: "resize-modal", mess: "default" });
    }
  }

  getValidationState({ dirty, validated, valid = null }: any) {
    return dirty || validated ? valid : null;
  }

  async onSubmit() {
    try {
      const validate = await this.checkAppointmentExists(
        this.getAppointmentClicked
      );
      if (!validate) {
        this.$alert(this.$t("APPOINTMENT_TAKEN") as string, "", {
          confirmButtonText: "OK",
          callback: (action) => {
            this.$router.replace({
              name: "open-appointment",
            });
          },
        });
      } else {
        await this.sendContactAppointment({
          objectId: this.objectId,
          key: this.secretKey,
          email: this.emailSend,
          data: this.prepareDataToSend(),
          noEmail: this.getConfig.sendConfirmation === "yes" ? false : true,
        });
        this.$router.replace({
          name: "confirm-appointment",
          query: {
            isSuccess: "1",
            propertyAddress: this.projectAddress,
            appointmentDateTime: moment(this.appointment.StartTime)
              .locale(this.$i18n.locale)
              .format("dddd, MMM D, YYYY HH:mm"),
          },
        });
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  public checkRoofs() {
    let error = 0;
    for (const confirm in this.roofsCheck) {
      if (this.roofsCheck.hasOwnProperty(confirm)) {
        // @ts-ignore
        const value: boolean = this.roofsCheck[confirm] as boolean;
        if (value) {
          const findCheck = this.form.roofs.filter(function (obj) {
            return obj === confirm;
          });
          const inputCheck = document.getElementById(`input-group-${confirm}`);
          if (inputCheck && findCheck.length === 0) {
            inputCheck.children[0].classList.add("warning");
            setTimeout(function () {
              if (inputCheck) {
                inputCheck.children[0].classList.remove("warning");
              }
            }, 1000);
            error++;
          }
        }
      }
    }
    if (error === 0) {
      this.showTermOfServices = false;
      this.formDisable = false;
    } else {
      this.showTermOfServices = true;
      this.formDisable = true;
    }
  }

  public prepareDataToSend() {
    const languageId =
      LANGUAGE_CONST.find((lang) => lang.name === this.getConfig.language)!
        .id || "nl";
    const letterTitle = this.$t("DEAR_" + this.form.AddressTitle) as string;
    const addressTitle = this.$t(this.form.AddressTitle) as string;
    const data = {
      ContactData: this.removeParamsNULL({
        Name: this.form.Name,
        FirstName: this.form.FirstName,
        LetterTitle: letterTitle,
        AddressTitle: addressTitle,
        Email: this.form.Email,
        PhoneNumber: this.form.PhoneNumber,
        // Content: this.form.Content,
        Subject: this.subject_mail,
        IsReceivedEmail: this.form.IsReceivedEmail,
        ObjectID: this.objectId,
        AppointmentID: this.appointmentId,
        LanguageId: languageId,
        IsProject: getBoolean(this.getConfig.isProject),
      }),
      headers: {
        ReCaptchaResponse: "",
        UseCaptcha: false,
      },
      SendMail: {
        HtmlBody: this.compileMailToString().innerHTML,
      },
    };
    return data;
  }

  public compileMailToString() {
    return new ContactAppointmentMail({
      i18n: this.$i18n,
      propsData: {
        FIELD_SITE_LINK: "",
        LOGO: this.getConfig.logo,
        FIELD_SUBJECT: "Appointment Online",
        FIELD_FIRSTNAME: this.form.FirstName,
        FIELD_NAME: this.form.Name,
        FIELD_PHONENUMBER: this.form.PhoneNumber,
        FIELD_EMAIL: this.form.Email,
        // FIELD_CONTENT: this.form.Content,

        FIELD_OBJECTID: this.getProject.ID,
        FIELD_PROPERTY_APPOINTMENT_TEXT: this.appointmentTime,
        FIELD_PROPERTY_ZIP: this.getProject.Zip,
        FIELD_PROPERTY_CITY: this.getProject.City,
        FIELD_PROPERTY_TYPE: this.getProject.WebIDName,

        FIELD_SITE_ADDRESS_TITLE: this.form.AddressTitle,
        FIELD_SITE_STREET_HOUSENUMBER: "",
        FIELD_SITE_CITY_ZIP: "",
        FIELD_SITE_PHONE: "",
        FIELD_SITE_EMAIL: "",
        buttonBackColor: this.buttonBackColor,
        font: this.font,
      },
    }).$mount().$el;
  }

  private removeParamsNULL(params: any) {
    const newParams: any = { ...params };
    for (const propName in newParams) {
      if (
        newParams[propName] === null ||
        newParams[propName] === undefined ||
        newParams[propName] === "" ||
        newParams[propName] === "none"
      ) {
        delete newParams[propName];
      }
    }
    return newParams;
  }
}
